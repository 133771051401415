// sonarignore
import { Text } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
  CheckboxHeader,
  formatDate,
  IncMaCoHeader,
  TableCell,
} from "innovate/utils/PreLaunch/PreLaunchHelper";
import { ScenarioType, UserData } from "innovate/utils/types";

export const clientId =
  import.meta.env.VITE_CLIENT_ID ?? "5d7c1d57-b6fd-4f46-bd7c-c9589ebdfef1";
export const redirectUri =
  import.meta.env.VITE_REDIRECT_URI ?? "https://innovatex.ai-stg.ab-inbev.com";
export const fixed =
  import.meta.env.VITE_API_URL ?? "https://dev.innox.ab-inbev.com/api/";

type CellComponent = (props: { row: Row<ScenarioType> }) => JSX.Element;

export const categoryDistributionRSA = { BEER: 6.11, "BEYOND BEER": 6.15 };
export const categoryDistributionBR = {
  BEER: 4.9,
  "BEYOND BEER": 22.8,
  NAB: 21.8,
  "NON ALC BEER": 5.9,
};


export const pptData = [
  {
    Zone: "Africa",
    Country: "South Africa",
    Financials_source: "UMAMI",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
  {
    Zone: "SAZ",
    Country: "Brazil",
    Financials_source: "CUBO",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
];

export const filterByCountry = (country: string) =>
  pptData.filter((item) => item.Country === country)[0];

export const chakraStyles = (
  customWidth: string,
  customFont: string,
  customAlign: string
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: customWidth,
    fontSize: customFont,
    fontWeight: "600",
    textAlign: customAlign,
    color: "black",
    bg: "white",
    padding: "0 16px 0",
    backgroundColor: "white !important",
    // border: "none",
    borderColor: state.isFocused
      ? "primaryBlack !important"
      : "border !important",
    borderRadius: "8px",
    boxShadow: "none !important",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1E1E1ECC",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    px: "0",
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    bg: "border",
    fontSize: "12px",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    px: "200px",
    bg: "green",
    cursor: "pointer",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "textBlack !important",
    fontSize: "12px",
    background: state.isFocused ? "border !important" : "none",
  }),
});
export const filterInfo: { [key: string]: string } = {
  "Price per HL": "Expected NR per HL",
  "MACO per HL": "Unplugged MACO/HL",
  Distribution: "% of POC where the product will be available",
};

export const desiredOrder = [
  "P_Brand",
  "P_Subbrand",
  "P_Flavour",
  "P_Category",
  "P_SubCategory",
  "P_Beer_Type",
  "P_Pack_Size",
  "P_Price_Segment",
  "P_ABV",
  "P_Pack_Return_Type",
  "P_MACO_per_HL",
  "P_Launch_Period",
];

export const getColumns = (
  isShared: boolean,
  editName: CellComponent,
  preCheckBox: CellComponent,
  actionbuttons: CellComponent
): ColumnDef<ScenarioType>[] => {
  const columns: ColumnDef<ScenarioType>[] = [
    {
      header: CheckboxHeader,
      accessorKey: "checkbox",
      cell: preCheckBox,
    },
    {
      header: "Scenario Name",
      accessorKey: "name",
      cell: editName,
    },
    {
      header: "Created On",
      accessorKey: "date",
      cell: formatDate,
    },

    {
      header: "Country",
      accessorKey: "Country",
      cell: TableCell,
    },

    ...(isShared
      ? [
          {
            header: "Created By",
            accessorKey: "created_by",
            cell: TableCell,
          },
        ]
      : []),
    {
      header: "Brand",
      accessorKey: "brand",
      cell: TableCell,
    },
    {
      header: "Total Volume",
      accessorKey: "incremental_nr",
      cell: TableCell,
    },
    {
      header: "Incremental Volume",
      accessorKey: "incremental_volume_%",
      cell: TableCell,
    },
    {
      header: IncMaCoHeader,
      accessorKey: "incremental_maco",
      cell: TableCell,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: actionbuttons,
    },
  ];
  return columns;
};
export const RegionalColumn = [
  {
    header: "Districts",
    accessorKey: "R_Districts",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "POC Segments",
    accessorKey: "R_POC_Segments",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];

export const BrazilRegionalColumn = [
  {
    header: "Regions",
    accessorKey: "R_Regions",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Channels",
    accessorKey: "R_Channels",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];

export const InnoColumns = (isSwitchOn: boolean, country: string) => {
    const columns = [
      {
        header: "Brand",
        accessorKey: "brand",
        cell: (props: { getValue: () => string }) => (
          <Text whiteSpace="normal" fontWeight={500} fontFamily="Avantt bold">
            {props.getValue()}
          </Text>
        ),
      },
      {
        header: "Pack Type",
        accessorKey: "packType",
        cell: (props: { getValue: () => string }) => (
          <Text whiteSpace="normal" fontFamily="Avantt bold">
            {props.getValue()}
          </Text>
        ),
      },
      {
        header: "Pack Size",
        accessorKey: "packSize",
        cell: (props: { getValue: () => string }) => (
          <Text whiteSpace="normal" fontFamily="Avantt bold">
            {props.getValue()}
          </Text>
        ),
      },
      {
        header: isSwitchOn ? "Volume (HL)" : "Volume %",
        accessorKey: "volume",
        cell: (props: { getValue: () => string }) => (
          <Text whiteSpace="normal" fontFamily="Avantt bold">
            {props.getValue()}
          </Text>
        ),
      },
    ];
  
    // Conditionally add the "Pack Quantity" column
    if (country === "UK" || country === "USA") {
      columns.splice(3, 0, {
        header: "Pack Quantity",
        accessorKey: "pack_quantity",
        cell: (props: { getValue: () => string }) => (
          <Text whiteSpace="normal" fontFamily="Avantt bold">
            {props.getValue()}
          </Text>
        ),
      });
    }
  
    return columns;
  };

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const exploreABS = [
    {
        "country": "ZAF",
        "abi": [
            {
                "type": "Default",
                "segment": "CORE",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "10.7"
                            },
                            {
                                "type": "NRB",
                                "value": "412.5"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RB",
                                "value": "19810.4"
                            },
                            {
                                "type": "CAN",
                                "value": "1526.7"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "21760"
                        }
                    }
                ]
            },
            {
                "type": "Default",
                "segment": "CORE+",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "7.2"
                            },
                            {
                                "type": "NRB",
                                "value": "575.7"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RB",
                                "value": "4086.0"
                            },
                            {
                                "type": "CAN",
                                "value": "1315.5"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "5984"
                        }
                    }
                ]
            },
            {
                "type": "Default",
                "segment": "P / SP",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "1352.7"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "154.0"
                            },
                            {
                                "type": "NRB",
                                "value": "205.1"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "1711"
                        }
                    }
                ]
            },
            {
                "type": "BUDWEISER",
                "segment": "P / SP",
                "Brand Category": "Tail",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "4.6"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "4"
                        }
                    }
                ]
            },
            {
                "type": "CARLING BLACK LABEL",
                "segment": "CORE",
                "Brand Category": "Mega",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "5.7"
                            },
                            {
                                "type": "NRB",
                                "value": "248.6"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "14451.6"
                            },
                            {
                                "type": "CAN",
                                "value": "954.0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "15659"
                        }
                    }
                ]
            },
            {
                "type": "CASTLE DOUBLE MALT",
                "segment": "CORE+",
                "Brand Category": "Expansion",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "28.4"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "293.1"
                            },
                            {
                                "type": "CAN",
                                "value": "93.6"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "415"
                        }
                    }
                ]
            },
            {
                "type": "CASTLE LAGER",
                "segment": "CORE",
                "Brand Category": "Mega",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "5.0"
                            },
                            {
                                "type": "NRB",
                                "value": "116.0"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "4035.4"
                            },
                            {
                                "type": "CAN",
                                "value": "329.6"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "4486"
                        }
                    }
                ]
            },
            {
                "type": "CASTLE LITE",
                "segment": "CORE+",
                "Brand Category": "Sustain",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "7.2"
                            },
                            {
                                "type": "NRB",
                                "value": "502.0"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "1786.9"
                            },
                            {
                                "type": "CAN",
                                "value": "1014.1"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "3310"
                        }
                    }
                ]
            },
            {
                "type": "CASTLE MILK STOUT",
                "segment": "CORE+",
                "Brand Category": "Sustain",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "45.4"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "2006.0"
                            },
                            {
                                "type": "CAN",
                                "value": "207.8"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "2259"
                        }
                    }
                ]
            },
            {
                "type": "CORONA",
                "segment": "P / SP",
                "Brand Category": "Mega",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "1176.2"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "82.6"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "1258"
                        }
                    }
                ]
            },
            {
                "type": "HANSA PILSNER",
                "segment": "CORE",
                "Brand Category": "Tail",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "47.9"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "844.7"
                            },
                            {
                                "type": "CAN",
                                "value": "153.2"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "1045"
                        }
                    }
                ]
            },
            {
                "type": "LION LAGER",
                "segment": "CORE",
                "Brand Category": "Tail",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "478.7"
                            },
                            {
                                "type": "CAN",
                                "value": "89.9"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "568"
                        }
                    }
                ]
            },
            {
                "type": "STELLA ARTOIS",
                "segment": "P / SP",
                "Brand Category": "Expansion",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "171.9"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "71.4"
                            },
                            {
                                "type": "NRB",
                                "value": "205.1"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "448"
                        }
                    }
                ]
            }
        ],
        "heineken beverages": [
            {
                "type": "Default",
                "segment": "CORE",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "14.9"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RB",
                                "value": "18.9"
                            },
                            {
                                "type": "CAN",
                                "value": "0.1"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "33"
                        }
                    }
                ]
            },
            {
                "type": "Default",
                "segment": "CORE+",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "2.2"
                            },
                            {
                                "type": "NRB",
                                "value": "346.1"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RB",
                                "value": "1459.3"
                            },
                            {
                                "type": "CAN",
                                "value": "470.9"
                            },
                            {
                                "type": "NRB",
                                "value": "37.3"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "2315"
                        }
                    }
                ]
            },
            {
                "type": "Default",
                "segment": "P / SP",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "2.2"
                            },
                            {
                                "type": "NRB",
                                "value": "540.3"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RB",
                                "value": "1246.5"
                            },
                            {
                                "type": "CAN",
                                "value": "446.2"
                            },
                            {
                                "type": "NRB",
                                "value": "430.7"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "2665"
                        }
                    }
                ]
            },
            {
                "type": "AMSTEL",
                "segment": "CORE+",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "2.0"
                            },
                            {
                                "type": "NRB",
                                "value": "50.9"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "883.8"
                            },
                            {
                                "type": "CAN",
                                "value": "126.8"
                            },
                            {
                                "type": "NRB",
                                "value": "37.3"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "1100"
                        }
                    }
                ]
            },
            {
                "type": "HEINEKEN",
                "segment": "P / SP",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "2.2"
                            },
                            {
                                "type": "NRB",
                                "value": "442.1"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "1246.5"
                            },
                            {
                                "type": "CAN",
                                "value": "440.6"
                            },
                            {
                                "type": "NRB",
                                "value": "430.6"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "2562"
                        }
                    }
                ]
            },
            {
                "type": "SOL",
                "segment": "P / SP",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "98.2"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "5.7"
                            },
                            {
                                "type": "NRB",
                                "value": "0.1"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "104"
                        }
                    }
                ]
            },
            {
                "type": "SOWETO GOLD",
                "segment": "CORE",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "18.6"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "18"
                        }
                    }
                ]
            },
            {
                "type": "TAFEL",
                "segment": "CORE",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "14.9"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "0.3"
                            },
                            {
                                "type": "CAN",
                                "value": "0.1"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "15"
                        }
                    }
                ]
            },
            {
                "type": "WINDHOEK",
                "segment": "CORE+",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0.3"
                            },
                            {
                                "type": "NRB",
                                "value": "295.1"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "575.4"
                            },
                            {
                                "type": "CAN",
                                "value": "344.0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "1214"
                        }
                    }
                ]
            }
        ],
        "total market": [
            {
                "type": "CORE",
                "segment": "",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "10.7"
                            },
                            {
                                "type": "NRB",
                                "value": "427.4"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "19829.3"
                            },
                            {
                                "type": "CAN",
                                "value": "1526.7"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "21794"
                        }
                    }
                ]
            },
            {
                "type": "CORE+",
                "segment": "",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "9.5"
                            },
                            {
                                "type": "NRB",
                                "value": "921.8"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "5545.3"
                            },
                            {
                                "type": "CAN",
                                "value": "1786.3"
                            },
                            {
                                "type": "NRB",
                                "value": "37.3"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "8300"
                        }
                    }
                ]
            },
            {
                "type": "Default",
                "segment": "",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "22.4"
                            },
                            {
                                "type": "NRB",
                                "value": "3242.2"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "26621.1"
                            },
                            {
                                "type": "CAN",
                                "value": "3913.3"
                            },
                            {
                                "type": "NRB",
                                "value": "673.2"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "34472"
                        }
                    }
                ]
            },
            {
                "type": "P / SP",
                "segment": "",
                "Brand Category": "",
                "items": [
                    {
                        "entry": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "0"
                            },
                            {
                                "type": "NRB",
                                "value": "0"
                            }
                        ]
                    },
                    {
                        "frequency": [
                            {
                                "type": "RGB",
                                "value": "0"
                            },
                            {
                                "type": "CAN",
                                "value": "2.2"
                            },
                            {
                                "type": "NRB",
                                "value": "1893.0"
                            }
                        ]
                    },
                    {
                        "intensity": [
                            {
                                "type": "RGB",
                                "value": "1246.5"
                            },
                            {
                                "type": "CAN",
                                "value": "600.2"
                            },
                            {
                                "type": "NRB",
                                "value": "635.8"
                            }
                        ]
                    },
                    {
                        "total": {
                            "type": "Total",
                            "value": "4377"
                        }
                    }
                ]
            }
        ],
        
            "deep_dive": {
                "progress_bar": {
                    "CORE": "63",
                    "CORE+": "24",
                    "P / SP": "13"
                },
                "segment": [
                    {
                        "category": "CORE",
                        "value": "21794124"
                    },
                    {
                        "category": "CORE+",
                        "value": "8300226"
                    },
                    {
                        "category": "P / SP",
                        "value": "4377763"
                    }
                ],
                "manufacture": [
                    {
                        "category": "ABI",
                        "value": "21760205"
                    },
                    {
                        "category": "HEINEKEN BEVERAGES",
                        "value": "33919"
                    }
                ],
                "brands": [
                    {
                        "category": "CARLING BLACK LABEL",
                        "value": "15659810"
                    },
                    {
                        "category": "CASTLE LAGER",
                        "value": "4485961"
                    },
                    {
                        "category": "HANSA PILSNER",
                        "value": "1045843"
                    },
                    {
                        "category": "LION LAGER",
                        "value": "568591"
                    },
                    {
                        "category": "SOWETO GOLD",
                        "value": "18564"
                    },
                    {
                        "category": "TAFEL",
                        "value": "15355"
                    }
                ]
            }
        
    }
]
export const drillCorePlus = {
    
        "progress_bar": {
            "CORE": "63",
            "CORE+": "24",
            "P / SP": "13"
        },
        "segment": [
            {
                "category": "CORE",
                "value": "21794124"
            },
            {
                "category": "CORE+",
                "value": "8300226"
            },
            {
                "category": "P / SP",
                "value": "4377763"
            }
        ],
        "manufacture": [
            {
                "category": "ABI",
                "value": "5984411"
            },
            {
                "category": "HEINEKEN BEVERAGES",
                "value": "2315815"
            }
        ],
        "brands": [
            {
                "category": "AMSTEL",
                "value": "1100941"
            },
            {
                "category": "CASTLE DOUBLE MALT",
                "value": "415047"
            },
            {
                "category": "CASTLE LITE",
                "value": "3310211"
            },
            {
                "category": "CASTLE MILK STOUT",
                "value": "2259153"
            },
            {
                "category": "WINDHOEK",
                "value": "1214874"
            }
        ]
    
}
export const drillpsp =  {

        "progress_bar": {
            "CORE": "63",
            "CORE+": "24",
            "P / SP": "13"
        },
        "segment": [
            {
                "category": "CORE",
                "value": "21794124"
            },
            {
                "category": "CORE+",
                "value": "8300226"
            },
            {
                "category": "P / SP",
                "value": "4377763"
            }
        ],
        "manufacture": [
            {
                "category": "ABI",
                "value": "1711742"
            },
            {
                "category": "HEINEKEN BEVERAGES",
                "value": "2666021"
            }
        ],
        "brands": [
            {
                "category": "BUDWEISER",
                "value": "4601"
            },
            {
                "category": "CORONA",
                "value": "1258783"
            },
            {
                "category": "HEINEKEN",
                "value": "2561999"
            },
            {
                "category": "SOL",
                "value": "104022"
            },
            {
                "category": "STELLA ARTOIS",
                "value": "448358"
            }
        ]
    
}
export const drillcore = {
        "progress_bar": {
            "CORE": "63",
            "CORE+": "24",
            "P / SP": "13"
        },
        "segment": [
            {
                "category": "CORE",
                "value": "21794124"
            },
            {
                "category": "CORE+",
                "value": "8300226"
            },
            {
                "category": "P / SP",
                "value": "4377763"
            }
        ],
        "manufacture": [
            {
                "category": "ABI",
                "value": "21760205"
            },
            {
                "category": "HEINEKEN BEVERAGES",
                "value": "33919"
            }
        ],
        "brands": [
            {
                "category": "CARLING BLACK LABEL",
                "value": "15659810"
            },
            {
                "category": "CASTLE LAGER",
                "value": "4485961"
            },
            {
                "category": "HANSA PILSNER",
                "value": "1045843"
            },
            {
                "category": "LION LAGER",
                "value": "568591"
            },
            {
                "category": "SOWETO GOLD",
                "value": "18564"
            },
            {
                "category": "TAFEL",
                "value": "15355"
            }
        ]
    
}
export const createColumns = (
  lastColumn: (props: { row: Row<UserData> }) => JSX.Element
) => [
  {
    header: "Email",
    accessorKey: "email",
    cell: (props: { getValue: () => string }) => (
      <p style={{ color: "#886612" }}> {props.getValue()}</p>
    ),
  },
  {
    header: "Name",
    accessorKey: "username",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Zone",
    accessorKey: "approval_for",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Role",
    accessorKey: "role",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Manage Access",
    accessorKey: "manage_access",
    cell: lastColumn,
  },
];
