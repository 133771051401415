
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { IMsalContext, useMsal } from "@azure/msal-react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { type AxiosRequestConfig, AxiosResponse } from "axios";
import { fixed } from "innovate/utils/Constants";
import {
  ApprovedUser,
  data,
  Flavour,
  InnoExploreResponse,
  ScenarioType,
  UserData,
} from "innovate/utils/types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AUTH_TOKEN_KEY = "Auth_token";
export type Response<T> =
  | {
      simulation: boolean;
      success: true;
      data: T;
    }
  | { success: false; err?: AxiosResponse };

type AuthResponse = { token_type: string; access_token: string };
type NavigateFunction = ReturnType<typeof useNavigate>;

export const getAuthToken = async (
  msalDetails: IMsalContext,
  navigate: NavigateFunction
) => {
  let token = sessionStorage.getItem(AUTH_TOKEN_KEY);
  if (!token) {
    try {
      const authResponse = await msalDetails.instance.acquireTokenSilent({
        scopes: ["user.read", "openid", "profile", "email"],
        account: msalDetails.accounts[0],
      });
      if (authResponse && authResponse.accessToken) {
        const ssoResponse = await axios<AuthResponse>({
          url: `${fixed}users/sso-login`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: authResponse.account,
        });

        if (ssoResponse.status === 200) {
          sessionStorage.setItem(AUTH_TOKEN_KEY, ssoResponse.data.access_token);
          token = ssoResponse.data.access_token;
        } else if (ssoResponse.status === 404) {
          navigate("/404", {
            state: { apiEndpoint: `${fixed}users/sso-login`, status: "404" },
          });
          return null;
        } else {
          navigate("/404", {
            state: {
              apiEndpoint: `${fixed}users/sso-login`,
              status: ssoResponse.status,
            },
          });
          console.error(ssoResponse);
          return null;
        }
      } else {
        navigate("/404", {
          state: { apiEndpoint: `${fixed}users/sso-login`, status: "401" },
        });
        return null;
      }
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        try {
          const authResponse = await msalDetails.instance.acquireTokenRedirect({
            scopes: ["user.read"],
            account: msalDetails.accounts[0],
            forceRefresh: true,
          });
          return `Bearer ${authResponse.accessToken}`;
        } catch (redirectError) {
          console.error("Error during token redirect:", redirectError);
          navigate("/404", {
            state: {
              apiEndpoint: `${fixed}users/sso-login`,
              status: redirectError.response?.status,
            },
          });
          return null;
        }
      } else {
        navigate("/404", {
          state: {
            apiEndpoint: `${fixed}users/sso-login`,
            status: err.response?.status,
          },
        });
        console.debug("error in getting token", err);
        return null;
      }
    }
  }
  if (token) {
    return `Bearer ${token}`;
  } else {
    return null;
  }
};

export const useIsAuthorized = () => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        await getAuthToken(msalDetails, navigate);
      } catch (err) {
        console.debug(err);
      }
    })();
  }, [msalDetails, navigate]);
};


const httpCall = async <T>(
  config: AxiosRequestConfig,
  msalDetails: IMsalContext,
  navigate: NavigateFunction
): Promise<Response<T>> => {
  let token = await getAuthToken(msalDetails, navigate);
  if (!token) return { success: false };

  const headerConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
      "Cache-Control": "no-store,no-cache,",
      Pragma: "no-cache",
    },
  };

  return axios<T>(headerConfig)
    .then(response => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch(async err => {
      console.error('Error occurred:', err);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          console.log("Token expired 401");
          sessionStorage.removeItem(AUTH_TOKEN_KEY);
          try {
            const authResponse = await msalDetails.instance.acquireTokenSilent({
              scopes: ["user.read", "openid", "profile", "email"],
              account: msalDetails.accounts[0],
              forceRefresh: true,
            });
            if (authResponse && authResponse.accessToken) {
              const token = `Bearer ${authResponse.accessToken}`;
              headerConfig.headers.Authorization = token;

              const retryResponse = await axios<T>(headerConfig);
              return {
                success: true,
                data: retryResponse.data,
              };
            } else {
              navigate("/")
              // logout(msalDetails);
              return { success: false };
            }
          } catch (silentError) {
            console.error("Error during silent token acquisition:", silentError);
            // logout(msalDetails);
            navigate("/")
            return { success: false };
          }
        } else {
          // navigate("/")
          console.error("Error in httpCall:", err);
          return { success: false, err: err.response };
        }
      } else {
        navigate("/")
        console.error("Network or CORS error:", err.message);
        return { success: false, err: { message: err.message, code: err.code } };
      }
    });
};

export const useAuthenticatedQuery = <T>(
  queryKey: unknown[],
  queryConfig: AxiosRequestConfig,
  options?: {
    refetchOnWindowFocus?: boolean;
    enabled?: boolean;
  }
): UseQueryResult<Response<T>> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useQuery({
    queryKey,
    queryFn: () => httpCall<T>(queryConfig, msalDetails, navigate),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    retry: false,
    enabled: options?.enabled ?? true, // Only run the query if enabled is true
  });
  const { isPending, error, data } = result;
  useEffect(() => {
    if (error) {
      navigate("/")
      console.error(error);
    }
  }, [error, isPending, data, queryKey]);
  return result;
};

export const useAuthenticatedMutation = <T, MutationData>(
  mutationKey: unknown[],
  getMutationConfig: (data: MutationData) => AxiosRequestConfig
): UseMutationResult<Response<T>, unknown, MutationData, unknown> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useMutation<Response<T>, unknown, MutationData, unknown>({
    mutationKey,
    mutationFn: (data: MutationData) =>
      httpCall<T>(getMutationConfig(data), msalDetails, navigate),
    retry: false,
  });
  const { isIdle, error, data } = result;
  useEffect(() => {
    if (error) {
      navigate("/")
      console.error("error");
    }
  }, [error, isIdle, data, mutationKey]);
  return result;
};
type ResponseData = {
  is_admin: boolean;
  success: boolean;
  data?: {
    is_admin?: boolean;
  };
};
type scenarioData = {
  simulations: ScenarioType[];
  success: boolean;
};
type ApprovedCountriesResponse = {
  markets: string[];
  success: boolean;
  data: {
    markets: string[];
  };
};

export interface SimulationNamesResponse {
  simulations: Flavour[];
  success: boolean;
  data: {
    simulations: Flavour[];
  };
}

export interface UserResponse {
  success: boolean;
  data: UserData[];
}

export interface innoExploreRes {
  success: boolean;
  data: UserData[];
}
export interface User2Response {
  market: never[];
  role_id: number;
  email: string;
  name: string;
  success: boolean;
  data: UserData[];
}
export const useAdminAuthorized = () => {
  const { data, isLoading } = useAuthenticatedQuery<ResponseData>(["getUser"], {
    url: `${fixed}users/is_admin`,
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  const isAdmin = data?.success ? data.data?.is_admin : undefined;
  return { isAdmin, isLoading };
};

export const useScenarios = (refetch: boolean) => {
  const { data, isLoading, isError } = useAuthenticatedQuery<scenarioData>(
    ["getScenarios", refetch],
    {
      url: `${fixed}simulations`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const fetchedScenarios = data?.success ? data.data.simulations : [];

  return {
    fetchedScenarios,
    isLoading,
    isError,
  };
};
export const useSharedScenarios = () => {
  const { data, isLoading } = useAuthenticatedQuery<scenarioData>(
    ["getSharedScenarios"],
    {
      url: `${fixed}simulations/sharedsimulations`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isSharedLoading = isLoading;

  const fetchedSharedScenarios = data?.success ? data.data.simulations : [];
  return {
    fetchedSharedScenarios,
    isSharedLoading,
  };
};

export const GetArpprovedCountries = () => {
  const { data, isLoading } = useAuthenticatedQuery<ApprovedCountriesResponse>(
    ["approvedCountries"],
    {
      url: `${fixed}users/get_markets`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isCountryLoading = isLoading;
  const approvedCountries = data?.success ? data.data.markets : [];
  return { approvedCountries, isCountryLoading };
};

export const GetSimulationNames = () => {
  const { data, isLoading } = useAuthenticatedQuery<SimulationNamesResponse>(
    ["currentScenario"],
    {
      url: `${fixed}get_simulation_names`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const SimulationName = data?.success ? data.data.simulations : [];
  return { SimulationName, isLoading };
};

export const GetApprovedUsers = () => {
  const { data, isLoading } = useAuthenticatedQuery<ApprovedUser>(
    ["getApprovedUser"],
    {
      url: `${fixed}users/approved`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isUserLoading = isLoading;
  const approvedUSers = data?.success
    ? data.data
    : {
        SA: [],
        BR: [],
      };
  return { approvedUSers, isUserLoading };
};

export const GetApprovalList = () => {
  const { data, isLoading } = useAuthenticatedQuery<UserResponse>(
    ["userList"],
    {
      url: `${fixed}users`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const approved = data?.success ? data.data : {};
  return {
    approved,
    isLoading,
  };
};

export const GetPendingApproval = () => {
  const { data, isLoading } = useAuthenticatedQuery<UserResponse>(
    ["pendingUsers"],
    {
      url: `${fixed}users/pending`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const pending = data?.success ? data.data : [];
  return {
    pending,
    isLoading,
  };
};
export const GetInnoExploreData = () => {
  const { data, isLoading } = useAuthenticatedQuery<any>(
    ["inno-explore"],
    {
      url: `${fixed}inno-explore`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const innoExploreData = data?.success && data?.data
  ? data.data
  : [];

  return {
    innoExploreData,
    isLoading,
  };
};

export const GetUser = (id: string) => {
  const { data, isLoading } = useAuthenticatedQuery<User2Response>(
    ["EditUser"],
    {
      url: `${fixed}user/${id}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const user = data?.success && data.data ? data.data : null;

  return {
    user,
    isLoading,
  };
};


export const logout = (msalDetails: IMsalContext) => {
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
  const account = msalDetails.accounts[0]; // Select the first account
  msalDetails.instance.logoutRedirect({
    account: account,
  }).then(r => console.log(r));
};

const fetchQuery = async (
  endpoint: string,
  data?: unknown,
  method: string = "POST"
) => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method !== "GET" && method !== "HEAD") {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}${endpoint}`,
      options
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    toast.success("Request successful");
    return responseData;
  } catch (error) {
    const errorMessage = (error as Error).message;
    toast.error(`Request failed: ${errorMessage}`);
    throw error;
  }
};

export default fetchQuery;